import api from '@/api';
const initialDownloadState = {
  taskId: null,
  status: 0,
  requestTime: null,
  downloadFileName: null,
  errorFileName: null,
  errorMessage: null,
};
const initialUploadState = {
  uplaodFile: {},
  userId: null,
  taskId: null,
  status: 0,
  requestTime: null,
  downloadFileName: null,
  errorFileName: null,
  errorMessage: null,
};

export default {
  setSelectedAccountTableRowData({ commit }, rowData) {
    commit('SET_SELECTED_ACCOUNT_TABLE_ROW_DATA', rowData);
  },
  setSelectedContactTableRowData({ commit }, rowData) {
    commit('SET_SELECTED_CONTACT_TABLE_ROW_DATA', rowData);
  },
  setSelectedStoreTableRowData({ commit }, rowData) {
    commit('SET_SELECTED_STORE_TABLE_ROW_DATA', rowData);
  },
  setSelectedOpportunityTableRowData({ commit }, rowData) {
    commit('SET_SELECTED_OPPORTUNITY_TABLE_ROW_DATA', rowData);
  },
  setSelectedEventTableRowData({ commit }, rowData) {
    commit('SET_SELECTED_EVENT_TABLE_ROW_DATA', rowData);
  },
  setSelectedTabs({ commit }, data) {
    commit('SET_SELECTED_TABS', data);
  },
  setSelectedAccountTabs({ commit }, data) {
    commit('SET_SELECTED_ACCOUNT_TABS', data);
  },
  setOpportunityDatePicker({ commit }, data) {
    commit('SET_OPPORTUNITY_DATE_PICKER', data);
  },
  setPaginationData({ commit }, data) {
    commit('SET_PAGINATION_DATA', data);
  },
  // setTableLists({ commit }, tableLists) {
  //   commit('SET_TABLE_LISTS', tableLists);
  // },
  // clearTableLists({ commit }, tableName) {
  //   commit('CLEAR_TABLE_LISTS', tableName);
  // },
  // setSubTabs({ commit }, subTabs) {
  //   commit('SET_SUB_TABS', subTabs);
  // },
  setSearchKeyword({ commit }, searchData) {
    commit('SET_SEARCH_KEYWORD', searchData);
  },
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },

  setCustomDateRange({ commit }, closeDate) {
    commit('SET_CUSTOM_DATE_RANGE', closeDate);
  },

  //details
  setAccountDetails({ commit }, accountDetails) {
    commit('SET_ACCOUNT_DETAILS', accountDetails);
  },
  setAccountManagerList({ commit }, accountManagerList) {
    commit('SET_ACCOUNT_MANAGER_LIST', accountManagerList);
  },
  setAccountSourceTypeList({ commit }, accountSourceTypeList) {
    commit('SET_ACCOUNT_SOURCE_TYPE_LIST', accountSourceTypeList);
  },
  setSpecificAccountSourceType(
    { commit },
    { oldAccountSourceType, newAccountSourceType }
  ) {
    commit('SET_SPECIFIC_ACCOUNT_SOURCE_TYPE', {
      oldAccountSourceType,
      newAccountSourceType,
    });
  },
  setContactTypes({ commit }, contactTypes) {
    commit('SET_CONTACT_TYPES', contactTypes);
  },
  setTimezoneList({ commit }, timezoneList) {
    commit('SET_TIMEZONE_LIST', timezoneList);
  },
  //trial end time update
  setTrialEndTime({ commit }, trialEndTime) {
    commit('SET_TRIAL_END_TIME', trialEndTime);
  },

  // subtabs
  // setSubTabsList({ commit }, subTabList) {
  //   commit('SET_SUB_TABS_LIST', subTabList);
  // },
  setAccountListInContactSubTabs({ commit }, accountList) {
    commit('SET_ACCOUNT_LIST_IN_CONTACT_SUB_TABS', accountList);
  },
  setSubSearchKeyword({ commit }, subSearchData) {
    commit('SET_SUB_SEARCH_KEYWORD', subSearchData);
  },
  // clearSubTabsTableLists({ commit }, tableName) {
  //   commit('CLEAR_SUB_TABS_TABLE_LISTS', tableName);
  // },
  clearAllSubTabsLists({ commit }) {
    commit('CLEAR_ALL_SUB_TABS_LISTS');
  },
  updateAccount({ commit }, account) {
    commit('UPDATE_ACCOUNT', account);
  },
  updateContact({ commit }, contact) {
    commit('UPDATE_CONTACT', contact);
  },
  updateStore({ commit }, store) {
    commit('UPDATE_STORE', store);
  },
  setSorting({ commit }, sorting) {
    commit('SET_SORTING', sorting);
  },

  setIsAddNewAccount({ commit }, isAddNewAccount) {
    commit('SET_IS_ADD_NEW_ACCOUNT', isAddNewAccount);
  },
  setIsAddNewContact({ commit }, isAddNewContact) {
    commit('SET_IS_ADD_NEW_CONTACT', isAddNewContact);
  },
  setIsAddNewOpportunity({ commit }, isAddNewOpportunity) {
    commit('SET_IS_ADD_NEW_OPPORTUNITY', isAddNewOpportunity);
  },
  setIsAddNewEvent({ commit }, isAddNewEvent) {
    commit('SET_IS_ADD_NEW_EVENT', isAddNewEvent);
  },
  //export
  checkDownloadStatus({ dispatch, getters }) {
    const download = getters.download;

    if (!download?.taskId || [3, 2].includes(download.status)) return;
    api.bearer
      .post(
        {
          taskId: download.taskId,
          templateType: 0,
        },
        'crm/file/template-task-status'
      )
      .then((response) => {
        if (!response) throw new Error('No response');
        const { ApiStatus, ApiStatusMessage, Data } = response.data;
        if (!ApiStatus) throw new Error(ApiStatusMessage);
        switch (Data.status) {
          case 0:
          case 4:
            setTimeout(() => {
              dispatch('checkDownloadStatus');
            }, 15000);
            dispatch('setDownload', {
              download: {
                status: 4,
              },
            });
            return;
          case 2:
            dispatch('setDownload', {
              download: {
                downloadFileName: Data.downloadFileName,
                status: 2,
              },
            });
            return;
          case 3:
          default:
            throw new Error('Export is failed. Please try again.');
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch('clearDownload');
      });
  },
  checkUploadStatus({ dispatch, getters, state }) {
    const upload = getters.upload;

    if (!upload?.taskId || upload.status === 3) return;
    api.bearer
      .post(
        {
          taskId: upload.taskId,
          templateType: 0,
        },
        'crm/file/template-task-status'
      )
      .then((response) => {
        if (!response) throw new Error('No response');
        const { ApiStatus, ApiStatusMessage, Data } = response.data;
        if (!ApiStatus) throw new Error(ApiStatusMessage);
        switch (Data.status) {
          case 0:
          case 4:
            setTimeout(() => {
              dispatch('checkUploadStatus');
            }, 15000);
            dispatch('setUpload', {
              upload: {
                status: 4,
              },
            });
            state.isSuccessUpload = false;
            return;
          case 2:
            dispatch('setUpload', {
              upload: {
                downloadFileName: Data.downloadFileName,
                status: 2,
              },
            });

            state.isSuccessUpload = {
              status: 2,
              color: 'success',
              title: 'Upload Successful',
              message: 'Your file is uploaded successfully.',
            };
            return;
          case 3:
            state.isSuccessUpload = {
              status: 3,
              color: 'danger',
              title: 'Upload Failed',
              message: Data.errorMessage,
            };
            return;
          default:
            throw new Error('Upload is failed. Please try again.');
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch('clearUpload');
      });
  },
  clearDownload({ dispatch }) {
    dispatch('setDownload', {
      willReset: true,
    });
  },
  clearUpload({ dispatch }) {
    dispatch('setUpload', {
      willReset: true,
    });
  },
  setDownload({ commit, rootGetters }, { download, willReset = false }) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_DOWNLOAD', { evaStoreId, download, willReset });
  },
  setUpload({ commit, rootGetters }, { upload, willReset = false }) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_UPLOAD', { evaStoreId, upload, willReset });
  },
  async downloadTemplate({ dispatch }, payload) {
    try {
      dispatch('setDownload', {
        download: {
          ...initialDownloadState,
          status: 4,
        },
      });
      const response = await api.bearer.post(
        payload,
        'crm/file/download-lead-template'
      );
      const { ApiStatusCode, ApiStatusMessage, Data } = response.data;
      if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
      dispatch('setDownload', {
        download: {
          taskId: Data.UUID,
          requestTime: new Date(),
        },
      });

      dispatch('checkDownloadStatus');
    } catch (error) {
      console.log(error);
      dispatch('clearDownload');
    }
  },
  async uploadTemplate({ dispatch }, payload) {
    try {
      dispatch('setUpload', {
        upload: {
          ...initialUploadState,
          status: 4,
          uplaodFile: payload.uplaodFile,
          userId: payload.userId,
        },
      });

      const request = new XMLHttpRequest();
      const formData = new FormData();
      request.open(
        'POST',
        `${process.env.VUE_APP_SERVICE_URL}crm/file/upload-lead-template`,
        true
      );
      request.setRequestHeader(
        'Access-Control-Allow-Headers',
        'Origin, X-Requested-With'
      );
      request.setRequestHeader('Access-Control-Allow-Origin', '*');
      request.setRequestHeader(
        'Authorization',
        'Bearer ' + localStorage.getItem('token')
      );
      request.onreadystatechange = async () => {
        if (request.readyState === 4 && request.status === 200) {
          let response = JSON.parse(request.response);
          const { Data } = response;
          dispatch('setUpload', {
            upload: {
              taskId: Data.UUID,
              requestTime: new Date(),
            },
          });

          dispatch('checkUploadStatus');
        } else if (request.readyState === 4 && request.status === 500) {
          throw new Error(request.ApiStatusMessage);
        }
      };
      formData.append('userId', payload.userId);
      formData.append('templateType', 0);
      formData.append('updateFile', payload.uplaodFile);
      request.send(formData);
    } catch (error) {
      console.log(error);
      dispatch('clearUpload');
    }
  },
};
