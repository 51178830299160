import moment from 'moment';

export default {
  isAddNewAccount: false,
  isAddNewContact: false,
  isAddNewOpportunity: false,
  isAddNewEvent: false,
  selectedAccountTableRowData: null,
  selectedContactTableRowData: null,
  selectedStoreTableRowData: null,
  selectedOpportunityTableRowData: null,
  selectedEventTableRowData: null,
  selectedTabs: null,
  selectedAccountTabs: null,
  opportunityDatePicker: {
    closeDate: null,
    isOverdueCloseDate: false,
  },
  // tableLists: {
  //   accountList: [],
  //   contactList: [],
  //   storeList: [],
  //   refAccountList: [],
  //   invoiceList: [],
  //   actionList: []
  // },
  paginationData: {},
  accountDetails: {},
  contactDetails: {},
  accountManagerList: [],
  accountSourceTypeMap: [],
  subTabs: {
    accountSubTabs: {
      contactList: [],
      storeList: [],
      subscriptionList: [],
      subscriptionLogList: [],
      invoiceList: [],
    },
    contactSubTabs: {
      storeList: [],
    },
  },
  contactTypes: [],
  timezoneList: [],
  accountListInContactSubTabs: [],
  subSearchKeywords: {
    accountSubTabs: {
      contactSearch: '',
      storeSearch: '',
      subscriptionSearch: '',
    },
    contactSubTabs: {
      accountSearch: '',
      storeSearch: '',
    },
  },
  searchKeyword: {
    accountList: '',
    contactList: '',
    storeList: '',
    invoiceList: '',
    opportunityList: '',
    manageStoresList: '',
  },
  filters: {
    accountList: {
      'query.filter.equal.accountType': null,
      'query.filter.equal.updatablePackageType': null,
      'query.filter.equal.reimbursementPackageStatus': null,
      'query.filter.equal.accountStatus': null,
      'query.filter.equal.salesAccountManager': null,
      'query.filter.equal.salesDevelopmentRepresentative': null,
      'query.filter.equal.customerSuccessManager': null,
      'query.filter.equal.storeType': null,
      'query.filter.equal.sourceType': null,
      'query.filter.equal.sourceDetailType': null,
    },
    contactList: {},
    storeList: {
      'query.filter.equal.storeType': null,
    },
    actionList: {
      'query.filter.equal.customDateRange': null,
      'query.filter.equal.actionType': null,
      'query.filter.equal.status': null,
      'query.filter.equal.assignee': null,
      'query.filter.equal.dueStatus': null,
    },
    invoiceList: {
      'query.filter.equal.paidStatus': null,
      'query.filter.equal.subscriptionType': null,
    },
    opportunityList: {
      'query.filter.equal.customDateRange': null,
      'query.filter.equal.reason': null,
      'query.filter.equal.forecast': [],
      'query.filter.equal.closeProbability': null,
      'query.filter.equal.accountSource': null,
      'query.filter.equal.accountSourceEvent': null,
      'query.filter.equal.stage': [],
      'query.filter.equal.opportunitySource': null,
      'query.filter.equal.solutionOfInterest': null,
      'query.filter.equal.salesAccountManager': null,
      'query.filter.equal.salesDevelopmentRepresentative': null,
      'query.filter.equal.actionStatus': [],
      'query.filter.equal.priority': null,
      'query.filter.equal.showOnlyOverDueOpportunity': false,
      'query.filter.equal.customDateRange': null,
    },
    eventList: {
      'query.filter.equal.customDateRange': {
        startDate: moment().startOf('month').format('YYYY/MM/DD'),
        endDate: moment().endOf('month').format('YYYY/MM/DD'),
      },
      'query.filter.equal.status': null,
      'query.filter.equal.condition': null,
      'query.filter.equal.attendee': [],
    },
  },
  sort: {
    accountList: {
      'query.sorting.column': null,
      'query.sorting.value': null,
    },
    storeList: {
      'query.sorting.column': null,
      'query.sorting.value': null,
    },
    storeDetailsList: {
      'query.sorting.column': null,
      'query.sorting.value': null,
    },
    actionList: {
      'query.sorting.column': 'operation_time',
      'query.sorting.value': 'desc',
    },
    invoiceList: {
      'query.sorting.column': null,
      'query.sorting.value': null,
    },
    opportunityList: {
      'query.sorting.column': 'idle_days',
      'query.sorting.value': 'asc',
    },
    eventList: {
      'query.sorting.column': null,
      'query.sorting.value': null,
    },
    manageStoresList: {
      'query.sorting.column': null,
      'query.sorting.value': null,
    },
  },
  //export
  download:
    JSON.parse(localStorage.getItem('download-account-list-bulk')) ?? {},
  upload: JSON.parse(localStorage.getItem('upload-account-list-bulk')) ?? {},
  isSuccessUpload: {
    status: 0,
    color: '',
    message: '',
    title: '',
  },
};
